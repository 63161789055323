import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../App.css'
import Logo from '../images/logo-white.svg'
import LogoIcon from '../images/logo-icon.png'
import { NavLink } from 'react-router-dom'

import { BadgeContext } from '../scripts/BadgeContext'
import { UserContext } from '../scripts/UserContext'

function Sidebar({menu}) {

    const { user } = useContext(UserContext)
    const { badges } = useContext(BadgeContext)

    return (
        <div id="sidebar">

            <div className="logoContainer">
                <img className="logo" src={Logo} alt="Logo" />
                <img className="logoIcon" src={LogoIcon} alt="Logo" />
            </div>
            
            <nav>
                <ul>
                    {
                    menu.map((el, index) => {
                        if(el.inMenu && el.allowedRoles.includes(user.role)){
                            return (
                                <li key={index}>
                                    <NavLink exact={el.exact} to={el.path} title={el.title} activeStyle={{color: '#008cdb'}}>
                                        <FontAwesomeIcon icon={el.icon} fixedWidth />
                                        <span>{el.title}</span>
                                        { badges[el.slug] ? (<span className="count">{ badges[el.slug] }</span>) : null }
                                        <div className="menuTooltip">{el.title}</div>
                                    </NavLink>
                                </li>
                            )
                        }
                        return ''
                    })
                    }
                </ul>
            </nav>

        </div>
    )

}

export default Sidebar