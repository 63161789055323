import React, { createContext, useState } from 'react'

export const UserContext = createContext(null)

export default ({children}) => {

    const [user, setUser] = useState(null)
    const [token, setToken] = useState(null)


    const checkScope = (scope, userInfo = null) => {
        let possible = false
        switch(scope){

            case 'createBK':
                possible = [1,2,3,4,5,6].includes(user.role)
                break

            case 'createPB':
                possible = [1,2,3,6].includes(user.role)
                break

            case 'createContact':
                possible = [1,2,3,4,5].includes(user.role)
                break

            case 'viewUser':
                //possible = [1,2,4,5,6].includes(user.role) || ( user.role == 3 && (userInfo.created_by == user.id || userInfo.assigned_to == user.id || userInfo.bearbeiter == user.id || userInfo.von == user.id) ) // assigned_to statt created_by
                possible = [1,2,5].includes(user.role) || ( [3].includes(user.role) && (userInfo.assigned_to == user.id || userInfo.bearbeiter == user.id || userInfo.von == user.id) )
                break

            case 'editUser':
                //possible = [1,2,4,5,6].includes(user.role) || ( user.role == 3 && (userInfo.created_by == user.id || userInfo.assigned_to == user.id || userInfo.bearbeiter == user.id || userInfo.von == user.id) ) // assigned_to statt created_by
                possible = [1,2].includes(user.role) || ( [3,5].includes(user.role) && (userInfo.assigned_to == user.id || userInfo.bearbeiter == user.id || userInfo.von == user.id) )
                break

            case 'editStartdate':
                possible = [1,2].includes(user.role)
                break

            case 'editCares':
                possible = [1,2].includes(user.role) || (user.role == 5 && userInfo.assigned_to == user.id && userInfo.von == user.id) // userInfo.von richtig???
                break

            case 'createTask':
                //possible = [1,2,3,4,5,6].includes(user.role)
                possible = [1,2].includes(user.role) || (user.role == 5 && userInfo.assigned_to == user.id)
                break

            case 'createGlobalTask':
                possible = [1,2,5].includes(user.role)
                break

            case 'editTask':
                possible = [1,2].includes(user.role) || userInfo.von == user.id || (userInfo.bearbeiter && userInfo.bearbeiter.includes(user.id))
                break

            case 'deleteTask':
                possible = user.role == 1 || (user.role == 2 && userInfo.von == user.id)
                break

            case 'createNote':
                possible = [1,2].includes(user.role) || (user.role == 5 && userInfo.assigned_to == user.id)
                break

            case 'pinNote':
                possible = user.role == 1 || (user.role == 2 && userInfo.created_by == user.id)
                break

            case 'editNote':
                possible = [1,2].includes(user.role) || userInfo.created_by == user.id
                break

            case 'deleteNote':
                possible = user.role == 1 || (user.role == 2 && userInfo.von == user.id)
                break

            case 'viewPBAddress':
                possible = [1,2,3,4,6].includes(user.role) || userInfo.user_role === 1
                break

            case 'viewPBContacts':
                possible = [1,2,3,4,6].includes(user.role) || userInfo.user_role === 1
                break

            case 'editUserRatings':
                possible = [1,2,4,5,6].includes(user.role) || (user.role == 3 && (userInfo.created_by == user.id || userInfo.assigned_to == user.id) ) // assigned_to statt created_by
                break

            case 'assignAdmin':
                possible = [1,2].includes(user.role)
                break

        }
        return possible
    }

    const data = {
        user, setUser,
        token, setToken,
        checkScope,
    }

    return (
        <UserContext.Provider value={data}>
            {children}
        </UserContext.Provider>
    )
    
}