import React, { useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCheck, faEye } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import '../../App.css'

import { AppContext } from '../../scripts/AppContext'
import { DataContext } from '../../scripts/DataContext'
import { UserContext } from '../../scripts/UserContext'
import { authAxios } from '../../scripts/Axios'

import Window from '../../components/Window'
import Table from '../../components/Table'
import Modal from '../../components/Modal'
import Selectbox from '../../components/Selectbox'
import RatingSelect from '../../components/RatingSelect'
import Alert from '../../components/Alert'

function RatingsScreen({user, setUser}) {

    const { setLoading } = useContext(AppContext)
    const { users } = useContext(DataContext)
    const { checkScope } = useContext(UserContext)

    const [alertMessage, setAlertMessage] = useState(null)

    const [addRatingModal, setAddRatingModal] = useState(false)
    const [addRatingInputs, setAddRatingInputs] = useState(null)

    const [editRatingModal, setEditRatingModal] = useState(false)
    const [editRatingID, setEditRatingID] = useState(null)
    const [editRatingInputs, setEditRatingInputs] = useState(null)

    const [viewRatingModal, setViewRatingModal] = useState(false)
    const [viewRating, setViewRating] = useState(null)

    function handleAddRating(){
        setAddRatingModal(true)
        if(user.user_role === 1){
            setAddRatingInputs({
                user_id: null,
                von_user_id: user.id,
                care_id: null,
                rating_wohnqualitaet: 1,
                rating_charakter: 1,
                rating_pflegeaufwand: 1,
                rating_umfeld: 1,
                rating_freizeit: 1,
                rating_text: '',
                created_at: moment().format('YYYY-MM-DDTHH:mm'),
            })
        }else{
            setAddRatingInputs({
                user_id: null,
                von_user_id: user.id,
                care_id: null,
                rating_erscheinungsbild: 1,
                rating_aufgeschlossenheit: 1,
                rating_kommunikation: 1,
                rating_kompetenz: 1,
                rating_haushalt: 1,
                rating_sprachkenntnisse: 1,
                rating_text: '',
                created_at: moment().format('YYYY-MM-DDTHH:mm'),
            })
        }
    }

    function saveAddRating(){

        setLoading(true)
        
        authAxios.post('rating', addRatingInputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Erstellt!' })
                setAddRatingModal(false)
                setUser({
                    ...user,
                    myratings: [
                        response.data.rating,
                        ...user.myratings,
                    ],
                })
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    

    function handleEditRating(id){
        setEditRatingModal(true)
        setEditRatingID(id)
        let rating = user.myratings.find(rating => rating.id === id)
        if(user.user_role === 1){
            setEditRatingInputs({
                user_id: rating.user_id,
                von_user_id: rating.von_user_id,
                care_id: rating.care_id,
                rating_wohnqualitaet: rating.rating_wohnqualitaet,
                rating_charakter: rating.rating_charakter,
                rating_pflegeaufwand: rating.rating_pflegeaufwand,
                rating_umfeld: rating.rating_umfeld,
                rating_freizeit: rating.rating_freizeit,
                rating_text: rating.rating_text,
                created_at: moment(rating.created_at).format('YYYY-MM-DDTHH:mm'),
            })
        }else{
            setEditRatingInputs({
                user_id: rating.user_id,
                von_user_id: rating.von_user_id,
                care_id: rating.care_id,
                rating_erscheinungsbild: rating.rating_erscheinungsbild,
                rating_aufgeschlossenheit: rating.rating_aufgeschlossenheit,
                rating_kommunikation: rating.rating_kommunikation,
                rating_kompetenz: rating.rating_kompetenz,
                rating_haushalt: rating.rating_haushalt,
                rating_sprachkenntnisse: rating.rating_sprachkenntnisse,
                rating_text: rating.rating_text,
                created_at: moment(rating.created_at).format('YYYY-MM-DDTHH:mm'),
            })
        }
    }    

    function handleViewRating(id){
        setViewRatingModal(true)
        let rating = user.ratings.find(rating => rating.id === id)
        setViewRating(rating)
    }

    function saveEditRating(){

        setLoading(true)
        
        authAxios.put('rating/' + editRatingID, editRatingInputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Gespeichert' })
                setEditRatingModal(false)
                setUser({
                    ...user,
                    myratings: user.myratings.map(rating => rating.id === response.data.rating.id ? response.data.rating : rating)
                })
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    function handleDeleteRating(){
        
        let q = window.confirm('Diese Bewertung wirklich löschen?')
        if(q){

            setLoading(true)
            
            authAxios.put('delete-rating/' + editRatingID, {
                user_id: editRatingInputs.user_id,
                von_user_id: user.id,
            })
            .then(response => {
    
                setLoading(false)
    
                if(response.data.type === 'error'){
                    setAlertMessage({ type: response.data.type, message: response.data.message })
                }else{
                    setAlertMessage({ type: response.data.type, message: response.data.message })
                    setEditRatingModal(false)
                    setUser({
                        ...user,
                        myratings: user.myratings.filter(rating => rating.id !== editRatingID)
                    })
                }
    
            })
            .catch(error => {
                console.log(error.response.data)
            })

        }

    }

    return (
        <div className="page-content">

            <Alert data={alertMessage} />

            <div className="window-row">

                <div className="col">

                    <Window name="userRatings" title="Erhaltene Bewertungen">

                        <Table name="tableUserRatings" data={user.ratings} headers={[
                            {
                                field: 'created_at',
                                label: 'Datum',
                                type: 'datetime',
                                size: 'm',
                            },
                            {
                                field: 'von_user_id',
                                label: 'Von',
                                type: 'user',
                                size: 'l',
                            },
                            {
                                field: 'rating_gesamt',
                                label: 'Bewertung',
                                type: 'rating',
                                size: 'm',
                            },
                            {
                                field: 'rating_text',
                                label: 'Beschreibung',
                                type: 'longtext',
                                size: 'xxl',
                            },
                        ]} onEdit={handleViewRating} editText="Ansehen" editIcon={faEye} />

                    </Window>

                    <Window name="userMyRatings" title="Geschriebene Bewertungen" actions={
                        <div style={{marginLeft:'auto'}}>
                            { checkScope('editUserRatings', { 'created_by': user.created_by, 'assigned_to': user.assigned_to }) ? (
                                <button onClick={e => handleAddRating()}><FontAwesomeIcon icon={faPlus} /> Bewertung erstellen</button>
                            ) : null }
                        </div>
                    }>

                        <Table name="tableUserMyRatings" data={user.myratings} headers={[
                            {
                                field: 'created_at',
                                label: 'Datum',
                                type: 'datetime',
                                size: 'm',
                            },
                            {
                                field: 'user_id',
                                label: 'Für',
                                type: 'user',
                                size: 'l',
                            },
                            {
                                field: 'rating_gesamt',
                                label: 'Bewertung',
                                type: 'rating',
                                size: 'm',
                            },
                            {
                                field: 'rating_text',
                                label: 'Beschreibung',
                                type: 'longtext',
                                size: 'xxl',
                            },
                        ]} onEdit={handleEditRating} />

                    </Window>

                </div>

            </div>
            

            { addRatingModal &&
                <Modal title="Bewertung erstellen" toggle={setAddRatingModal} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setAddRatingModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveAddRating()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>
                    
                    <div className="row">
                        <div className="col">
                            <label>Zu bewertende Person<br />
                            <Selectbox options={users.filter(u => u.user_role !== user.user_role).map(u => ({label: u.fullname, value: u.id}))} name="user_id" state={addRatingInputs} setState={setAddRatingInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Betreuung<br />
                            <Selectbox options={user.cares.filter(c => c.status !== 1 && ((user.user_role === 1 && c.pb_user_id === addRatingInputs.user_id) || (user.user_role === 2 && c.bk_user_id === addRatingInputs.user_id))).map(c => ({label: moment(c.startdatum).format('DD.MM.YYYY') + ' - ' + moment(c.enddatum).format('DD.MM.YYYY'), value: c.id}))} name="care_id" state={addRatingInputs} setState={setAddRatingInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Datum der Bewertung<br />
                            <input type="datetime-local" value={addRatingInputs.created_at} onChange={e => setAddRatingInputs({...addRatingInputs, created_at: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    { user.user_role === 1 ? (
                        <div style={{ marginTop: 20, marginBottom: 20 }}>
                            <div className="row">
                                <div className="col">
                                    <label>Wohnqualität</label>
                                    <RatingSelect name="rating_wohnqualitaet" state={addRatingInputs} setState={setAddRatingInputs} />
                                </div>
                                <div className="col">
                                    <label>Charakter</label>
                                    <RatingSelect name="rating_charakter" state={addRatingInputs} setState={setAddRatingInputs} />
                                </div>
                                <div className="col">
                                    <label>Pflegeaufwand</label>
                                    <RatingSelect name="rating_pflegeaufwand" state={addRatingInputs} setState={setAddRatingInputs} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>Umfeld</label>
                                    <RatingSelect name="rating_umfeld" state={addRatingInputs} setState={setAddRatingInputs} />
                                </div>
                                <div className="col">
                                    <label>Freizeit</label>
                                    <RatingSelect name="rating_freizeit" state={addRatingInputs} setState={setAddRatingInputs} />
                                </div>
                                <div className="col">
                                </div>
                            </div>
                        </div>
                    ):(
                        <div style={{ marginTop: 20, marginBottom: 20 }}>
                            <div className="row">
                                <div className="col">
                                    <label>Erscheinungsbild</label>
                                    <RatingSelect name="rating_erscheinungsbild" state={addRatingInputs} setState={setAddRatingInputs} />
                                </div>
                                <div className="col">
                                    <label>Aufgeschlossenheit</label>
                                    <RatingSelect name="rating_aufgeschlossenheit" state={addRatingInputs} setState={setAddRatingInputs} />
                                </div>
                                <div className="col">
                                    <label>Zusammenarbeit</label>
                                    <RatingSelect name="rating_kommunikation" state={addRatingInputs} setState={setAddRatingInputs} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>Kompetenz</label>
                                    <RatingSelect name="rating_kompetenz" state={addRatingInputs} setState={setAddRatingInputs} />
                                </div>
                                <div className="col">
                                    <label>Haushalt</label>
                                    <RatingSelect name="rating_haushalt" state={addRatingInputs} setState={setAddRatingInputs} />
                                </div>
                                <div className="col">
                                    <label>Sprachkenntnisse</label>
                                    <RatingSelect name="rating_sprachkenntnisse" state={addRatingInputs} setState={setAddRatingInputs} />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col">
                            <label>Anmerkungen<br />
                            <textarea value={addRatingInputs.rating_text} onChange={e => setAddRatingInputs({...addRatingInputs, rating_text: e.target.value})}></textarea>
                            </label>
                        </div>
                    </div>

                </Modal>
            }

            { editRatingModal &&
                <Modal title="Bewertung bearbeiten" toggle={setEditRatingModal} actions={
                    <div className="modal-actions">
                        <button className="deleteLink" onClick={e => handleDeleteRating()}>Bewertung löschen</button>
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setEditRatingModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveEditRating()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>
                    
                    
                    <div className="row">
                        <div className="col">
                            <label>Zu bewertende Person<br />
                            <Selectbox options={users.filter(u => u.user_role !== user.user_role).map(u => ({label: u.fullname, value: u.id}))} name="user_id" state={editRatingInputs} setState={setEditRatingInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Betreuung<br />
                            <Selectbox options={user.cares.filter(c => c.status !== 1 && ((user.user_role === 1 && c.pb_user_id === editRatingInputs.user_id) || (user.user_role === 2 && c.bk_user_id === editRatingInputs.user_id))).map(c => ({label: moment(c.startdatum).format('DD.MM.YYYY') + ' - ' + moment(c.enddatum).format('DD.MM.YYYY'), value: c.id}))} name="care_id" state={editRatingInputs} setState={setEditRatingInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Datum der Bewertung<br />
                            <input type="datetime-local" value={editRatingInputs.created_at} onChange={e => setEditRatingInputs({...editRatingInputs, created_at: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    { user.user_role === 1 ? (
                        <div style={{ marginTop: 20, marginBottom: 20 }}>
                            <div className="row">
                                <div className="col">
                                    <label>Wohnqualität</label>
                                    <RatingSelect name="rating_wohnqualitaet" state={editRatingInputs} setState={setEditRatingInputs} />
                                </div>
                                <div className="col">
                                    <label>Charakter</label>
                                    <RatingSelect name="rating_charakter" state={editRatingInputs} setState={setEditRatingInputs} />
                                </div>
                                <div className="col">
                                    <label>Pflegeaufwand</label>
                                    <RatingSelect name="rating_pflegeaufwand" state={editRatingInputs} setState={setEditRatingInputs} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>Umfeld</label>
                                    <RatingSelect name="rating_umfeld" state={editRatingInputs} setState={setEditRatingInputs} />
                                </div>
                                <div className="col">
                                    <label>Freizeit</label>
                                    <RatingSelect name="rating_freizeit" state={editRatingInputs} setState={setEditRatingInputs} />
                                </div>
                                <div className="col">
                                </div>
                            </div>
                        </div>
                    ):(
                        <div style={{ marginTop: 20, marginBottom: 20 }}>
                            <div className="row">
                                <div className="col">
                                    <label>Erscheinungsbild</label>
                                    <RatingSelect name="rating_erscheinungsbild" state={editRatingInputs} setState={setEditRatingInputs} />
                                </div>
                                <div className="col">
                                    <label>Aufgeschlossenheit</label>
                                    <RatingSelect name="rating_aufgeschlossenheit" state={editRatingInputs} setState={setEditRatingInputs} />
                                </div>
                                <div className="col">
                                    <label>Zusammenarbeit</label>
                                    <RatingSelect name="rating_kommunikation" state={editRatingInputs} setState={setEditRatingInputs} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>Kompetenz</label>
                                    <RatingSelect name="rating_kompetenz" state={editRatingInputs} setState={setEditRatingInputs} />
                                </div>
                                <div className="col">
                                    <label>Haushalt</label>
                                    <RatingSelect name="rating_haushalt" state={editRatingInputs} setState={setEditRatingInputs} />
                                </div>
                                <div className="col">
                                    <label>Sprachkenntnisse</label>
                                    <RatingSelect name="rating_sprachkenntnisse" state={editRatingInputs} setState={setEditRatingInputs} />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col">
                            <label>Anmerkungen<br />
                            <textarea value={editRatingInputs.rating_text} onChange={e => setEditRatingInputs({...editRatingInputs, rating_text: e.target.value})}></textarea>
                            </label>
                        </div>
                    </div>
                    

                </Modal>
            }

            { viewRatingModal &&
                <Modal title="Bewertung ansehen" toggle={setViewRatingModal} actions={
                    <div className="modal-actions">
                        <button className="cancelButton" onClick={e => setViewRatingModal(false)}>Schliessen</button>
                    </div>
                }>
                    
                    
                    <div className="row">
                        <div className="col">
                            <p>Von <strong>{ users.find(u => u.id === viewRating.von_user_id) ? users.find(u => u.id === viewRating.von_user_id).fullname : '-' }</strong> am <strong>{ moment(viewRating.created_at).format('DD.MM.YYYY HH:mm') }</strong></p>
                        </div>
                    </div>
                    { user.user_role === 2 ? (
                        <div style={{ marginBottom: 20 }}>
                            <div className="row">
                                <div className="col">
                                    <label>Wohnqualität</label>
                                    <RatingSelect name="rating_wohnqualitaet" viewOnly={true} state={viewRating} />
                                </div>
                                <div className="col">
                                    <label>Charakter</label>
                                    <RatingSelect name="rating_charakter" viewOnly={true} state={viewRating} />
                                </div>
                                <div className="col">
                                    <label>Pflegeaufwand</label>
                                    <RatingSelect name="rating_pflegeaufwand"viewOnly={true}  state={viewRating} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>Umfeld</label>
                                    <RatingSelect name="rating_umfeld"viewOnly={true}  state={viewRating} />
                                </div>
                                <div className="col">
                                    <label>Freizeit</label>
                                    <RatingSelect name="rating_freizeit"viewOnly={true}  state={viewRating} />
                                </div>
                                <div className="col">
                                </div>
                            </div>
                        </div>
                    ):(
                        <div style={{ marginBottom: 20 }}>
                            <div className="row">
                                <div className="col">
                                    <label>Erscheinungsbild</label>
                                    <RatingSelect name="rating_erscheinungsbild" viewOnly={true} state={viewRating} />
                                </div>
                                <div className="col">
                                    <label>Aufgeschlossenheit</label>
                                    <RatingSelect name="rating_aufgeschlossenheit" viewOnly={true} state={viewRating} />
                                </div>
                                <div className="col">
                                    <label>Zusammenarbeit</label>
                                    <RatingSelect name="rating_kommunikation" viewOnly={true} state={viewRating} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>Kompetenz</label>
                                    <RatingSelect name="rating_kompetenz" viewOnly={true} state={viewRating} />
                                </div>
                                <div className="col">
                                    <label>Haushalt</label>
                                    <RatingSelect name="rating_haushalt" viewOnly={true} state={viewRating} />
                                </div>
                                <div className="col">
                                    <label>Sprachkenntnisse</label>
                                    <RatingSelect name="rating_sprachkenntnisse" viewOnly={true} state={viewRating} />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col">
                            <label>Anmerkungen</label>
                            <p>{ viewRating.rating_text }</p>
                        </div>
                    </div>
                    

                </Modal>
            }

        </div>
    )

}

export default RatingsScreen