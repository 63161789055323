import React, { useEffect, useState, useContext } from 'react'
import '../App.css'
import moment from 'moment'
import 'moment/locale/de'
import { Scrollbars } from 'react-custom-scrollbars'
import { useHistory } from 'react-router-dom'
import Axios from 'axios'
import { authAxios } from '../scripts/Axios'

import { AppContext } from '../scripts/AppContext'
import { updatePref, getPref } from '../scripts/Functions'

import Window from '../components/Window'
import Table from '../components/Table'
import Alert from '../components/Alert'
import Selectbox from '../components/Selectbox'

function DashboardScreen() {

    moment.locale('de')

    const { setLoading } = useContext(AppContext)
    const history = useHistory()

    const [alertMessage, setAlertMessage] = useState(null)

    const [newUsers, setNewUsers] = useState([])
    const [newFinishedUsers, setNewFinishedUsers] = useState([])
    const [showUnfinishedUsers, setShowUnfinishedUsers] = useState(false)

    const [urgentCares, setUrgentCares] = useState([])

    const [confirmationMessages, setConfirmationMessages] = useState([])
    const [unconfirmedMessages, setUnconfirmedMessages] = useState([])
    const [showConfirmedMessages, setShowConfirmedMessages] = useState(false)

    const [leistungsnachweise, setLeistungsnachweise] = useState([])
    const [missingLeistungsnacheise, setMissingLeistungsnachweise] = useState([])
    const [showAllLeistungsnachweise, setShowAllLeistungsnachweise] = useState(false)
    const leistungsnachweiseOptions = new Array(12).fill(undefined).map((e, i) => moment().subtract(i, 'months') )
    const [leistungsnachweisePeriod, setLeistungsnachweisePeriod] = useState({ month: leistungsnachweiseOptions[0].format('YYYY-MM') })


    useEffect(() => {

        async function getDashboardData(){

            setLoading(true)

            const getNewUsers = authAxios.get('new-users')
            const getUrgentCares = authAxios.get('urgent-cares')
            const getConfirmationMessages = authAxios.get('confirmation-messages')
            const getLeistungsnachweise = authAxios.get('leistungsnachweise')
            
            await Axios.all([
                getNewUsers,
                getUrgentCares,
                getConfirmationMessages,
                getLeistungsnachweise
            ]).then(Axios.spread(function(response1, response2, response3, response4) {

                if(response1.data.type === 'error'){
                    setAlertMessage({ type: response1.data.type, message: response1.data.message })
                }else{
                    setNewUsers(response1.data.users)
                    setTableOptionsPref()
                }

                if(response2.data.type === 'error'){
                    setAlertMessage({ type: response2.data.type, message: response2.data.message })
                }else{
                    setUrgentCares(response2.data.users)
                }

                if(response3.data.type === 'error'){
                    setAlertMessage({ type: response3.data.type, message: response3.data.message })
                }else{
                    setConfirmationMessages(response3.data.messages)
                }

                if(response4.data.type === 'error'){
                    setAlertMessage({ type: response4.data.type, message: response4.data.message })
                }else{
                    setLeistungsnachweise(response4.data.nachweise)
                }

                setLoading(false)

            }))
        
        }

        getDashboardData()

    }, [])


    useEffect(() => {
        setNewFinishedUsers(newUsers.filter(user => user.konto === 2))
    }, [newUsers])

    useEffect(() => {
        setUnconfirmedMessages(confirmationMessages.filter(message => message.confirmed_at === null))
    }, [confirmationMessages])

    useEffect(() => {
        setMissingLeistungsnachweise(leistungsnachweise.filter(nachweis => nachweis.status === 0))
    }, [leistungsnachweise])


    function handleEditUser(id){
        history.push('/user/' + id)
    }
    
        
    async function setTableOptionsPref(){
        let tableOptionsPref = await getPref('tableNewUsersOptions')
        if(tableOptionsPref !== null){
            setShowUnfinishedUsers(tableOptionsPref.showUnfinishedUsers)
        }
    }

    function changeShowUnfinishedUsers(){
        let state = !showUnfinishedUsers
        setShowUnfinishedUsers(state)
        updatePref('tableNewUsersOptions', {
            showUnfinishedUsers: state,
        })
    }

    function changeShowConfirmedMessages(){
        let state = !showConfirmedMessages
        setShowConfirmedMessages(state)
        updatePref('tableConfirmationMessages', {
            showConfirmedMessages: state,
        })
    }

    function changeShowAllLeistungsnachweise(){
        let state = !showAllLeistungsnachweise
        setShowAllLeistungsnachweise(state)
        updatePref('tableLeistungsnachweise', {
            showAllLeistungsnachweise: state,
        })
    }

    return (
        <Scrollbars autoHide={true} autoHideDuration={250} autoHideTimeout={500} className="page">
            <div className="page-content">

                <Alert data={alertMessage} />

                <div className="window-row">
                    <div className="col">

                        <Window name="urgentCares" title="Dringend benötigte Betreuungen" actions={urgentCares.length !== 0 && <span className="count">{urgentCares.length}</span>}>

                            <Table name="tableUrgentCares" data={urgentCares} headers={[
                                {
                                    field: 'konto',
                                    label: '',
                                    type: 'account',
                                    size: 'xxs',
                                },
                                {
                                    field: 'id',
                                    label: 'Plegebedürftige Person',
                                    type: 'user',
                                    size: 'l',
                                },
                                {
                                    field: 'startdatum',
                                    label: 'Startdatum',
                                    type: 'date',
                                    size: 'm',
                                },
                                {
                                    field: 'pflegegrad',
                                    label: 'Pflegegrad',
                                    type: 'carelevel',
                                    size: 's',
                                },
                            ]} onEdit={handleEditUser} />

                        </Window>

                    </div>
                    <div className="col">

                        <Window name="newUsers" title="Neue Kontakte" actions={newUsers.length !== 0 && <span className="count">{newUsers.filter(u => u.konto === 2).length}</span>}>

                            <Table name="tableNewUsers" data={showUnfinishedUsers ? newUsers : newFinishedUsers} headers={[
                                {
                                    field: 'konto',
                                    label: '',
                                    type: 'account',
                                    size: 'xxs',
                                },
                                {
                                    field: 'created_at',
                                    label: 'Datum',
                                    type: 'datetime',
                                    size: 'm',
                                },
                                {
                                    field: 'user_role',
                                    label: 'Typ',
                                    type: 'userRole',
                                    size: 'xs',
                                },
                                {
                                    field: 'id',
                                    label: 'Name',
                                    type: 'user',
                                    size: 'xl',
                                },
                                {
                                    field: 'origin',
                                    label: 'Ursprung',
                                    type: 'origin',
                                    size: 'm',
                                },
                            ]} onEdit={handleEditUser} footerContent={
                                <label className="checkContainer">
                                    <input type="checkbox" checked={showUnfinishedUsers} onChange={changeShowUnfinishedUsers} /> <span>Unfertige Profile anzeigen</span>
                                </label>
                            } />

                        </Window>

                    </div>
                </div>

                <div className="window-row">
                    <div className="col">

                        <Window name="confirmationMessages" title="Zu bestätigende Nachrichten" actions={unconfirmedMessages.length !== 0 && <span className="count">{unconfirmedMessages.filter(m => m.confirmed_at === null).length}</span>}>

                            <Table name="tableConfirmationMessages" data={showConfirmedMessages ? confirmationMessages : unconfirmedMessages} headers={[
                                {
                                    field: 'confirmation',
                                    label: 'Status',
                                    type: 'confirmstatus',
                                    size: 's',
                                },
                                {
                                    field: 'created_at',
                                    label: 'Gesendet am',
                                    type: 'datetime',
                                    size: 's',
                                },
                                {
                                    field: 'confirmed_at',
                                    label: 'Bestätigt am',
                                    type: 'datetime',
                                    size: 's',
                                },
                                {
                                    field: 'user_id',
                                    label: 'Empfänger',
                                    type: 'recipient',
                                    size: 'l',
                                },
                                {
                                    field: 'betreff',
                                    label: 'Betreff',
                                    type: 'text',
                                    size: 'xl',
                                },
                                {
                                    field: 'assigned_to',
                                    label: 'Zugewiesen an',
                                    type: 'admin',
                                    size: 'l',
                                },
                                {
                                    field: 'anhang',
                                    label: 'Anhang',
                                    type: 'attachments',
                                    size: 'xs',
                                },
                            ]}
                            footerContent={
                                <label className="checkContainer">
                                    <input type="checkbox" checked={showConfirmedMessages} onChange={changeShowConfirmedMessages} /> <span>Bestätigte Nachrichten anzeigen</span>
                                </label>
                            } />

                        </Window>

                    </div>
                </div>

                <div className="window-row">
                    <div className="col">

                        <Window name="leistungsnachweise" title="Leistungsnachweise" actions={
                            <div style={{ display: 'flex', flex: 1 }}>
                                { missingLeistungsnacheise.length !== 0 && <span className="count">{missingLeistungsnacheise.filter(n => n.status === 0).length}</span> }
                                <div style={{ marginLeft: 'auto', marginRight: 4, width: 200 }}>                                    
                                    <Selectbox options={leistungsnachweiseOptions.map(date => ({label: date.format('MMMM YYYY'), value: date.format('YYYY-MM') }))} name="month" state={leistungsnachweisePeriod} setState={setLeistungsnachweisePeriod} />
                                </div>
                            </div>
                        }>

                            <Table name="tableLeistungsnachweise" data={showAllLeistungsnachweise ?
                                leistungsnachweise.filter(n => moment(n.created_at).isSame(moment(leistungsnachweisePeriod.month+'-01'), 'month'))
                                : missingLeistungsnacheise.filter(n => moment(n.created_at).isSame(moment(leistungsnachweisePeriod.month+'-01'), 'month'))
                            } headers={[
                                {
                                    field: 'confirmation',
                                    label: 'Status',
                                    type: 'leistungsnachweisstatus',
                                    size: 's',
                                },
                                {
                                    field: 'created_at',
                                    label: 'Zeitraum',
                                    type: 'monthYear',
                                    size: 'm',
                                },
                                {
                                    field: 'user_id',
                                    label: 'Kunde',
                                    type: 'user',
                                    size: 'l',
                                },
                            ]}
                            footerContent={
                                <label className="checkContainer">
                                    <input type="checkbox" checked={showAllLeistungsnachweise} onChange={changeShowAllLeistungsnachweise} /> <span>Erhaltene Leistungsnachweise anzeigen</span>
                                </label>
                            } />

                        </Window>

                    </div>
                    <div className="col"></div>
                </div>


            </div>
        </Scrollbars>
    )

}

export default DashboardScreen