import React, { createContext, useEffect, useState, useContext } from 'react'

import { authAxios } from '../scripts/Axios'
import { AppContext } from './AppContext'

import IconFILE from '../images/files/file.svg'
import IconCSV from '../images/files/csv.svg'
import IconDOC from '../images/files/doc.svg'
import IconJPG from '../images/files/jpg.svg'
import IconMP3 from '../images/files/mp3.svg'
import IconMP4 from '../images/files/mp4.svg'
import IconPDF from '../images/files/pdf.svg'
import IconPNG from '../images/files/png.svg'
import IconPPT from '../images/files/ppt.svg'
import IconXLS from '../images/files/xls.svg'
import IconZIP from '../images/files/zip.svg'


export const DataContext = createContext(null)

export default ({children}) => {
    
    const { setDataLoaded } = useContext(AppContext)
    const [profileOptions, setProfileOptions] = useState(null)
    const [contactTypes, setContactTypes] = useState(null)
    const [adminRoles, setAdminRoles] = useState(null)
    const [admins, setAdmins] = useState([])
    const [users, setUsers] = useState([])
    const [systemTemplates, setSystemTemplates] = useState([])

    const countryList = require("i18n-iso-countries")
    countryList.registerLocale(require("i18n-iso-countries/langs/de.json"))
    const countries = countryList.getNames('de', {select: 'official'})

    const fileIcons = [
        { src: IconFILE, types: [] },
        { src: IconCSV, types: ['text/comma-separated-values'] },
        { src: IconDOC, types: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'] },
        { src: IconJPG, types: ['image/jpeg'] },
        { src: IconMP3, types: ['audio/mpeg'] },
        { src: IconMP4, types: ['video/mpeg', 'video/mp4'] },
        { src: IconPDF, types: ['application/pdf'] },
        { src: IconPNG, types: ['image/png'] },
        { src: IconPPT, types: ['application/mspowerpoint'] },
        { src: IconXLS, types: ['application/msexcel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] },
        { src: IconZIP, types: ['application/zip', 'application/gzip'] },
    ]

    useEffect(() => {
        if(profileOptions !== null && contactTypes !== null && adminRoles !== null){
            setDataLoaded(true)
        }
    }, [profileOptions, contactTypes, adminRoles])


    useEffect(() => {

        authAxios.get('profileOptions')
        .then(response => {

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{

                let options = response.data.options.map(opt => {
                    return {
                        ...opt,
                        label: opt.value,
                        value: opt.id,
                    }
                })
                setProfileOptions(options)
                
            }

        })
        .catch(error => {
            console.log(error)
        })

        authAxios.get('contactTypes')
        .then(response => {

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setContactTypes(response.data.options)
            }

        })
        .catch(error => {
            console.log(error)
        })

        authAxios.get('adminRoles')
        .then(response => {

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setAdminRoles(response.data.options)
            }

        })
        .catch(error => {
            console.log(error)
        })

        authAxios.get('admins')
        .then(response => {

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setAdmins(response.data.admins)
            }

        })
        .catch(error => {
            console.log(error)
        })
        
        authAxios.get('users')
        .then(response => {

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setUsers(response.data.users)
            }

        })
        .catch(error => {
            console.log(error)
        })

        authAxios.get('system-templates')
        .then(response => {
            if(response.data.type !== 'error'){
                setSystemTemplates(response.data.templates)
            }
        })
        .catch(error => {
            console.log(error)
        })

    }, [])

    const data = {
        profileOptions,
        contactTypes,
        admins, setAdmins,
        adminRoles,
        users,
        fileIcons,
        countries,
        systemTemplates,
    }

    return (
        <DataContext.Provider value={data}>
            {children}
        </DataContext.Provider>
    )
    
}




