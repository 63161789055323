import React, { useContext, useState } from 'react'
import '../App.css'
import { Scrollbars } from 'react-custom-scrollbars'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCheck } from '@fortawesome/free-solid-svg-icons'
import { authAxios } from '../scripts/Axios'

import Window from '../components/Window'
import Table from '../components/Table'
import Modal from '../components/Modal'
import Alert from '../components/Alert'
import Selectbox from '../components/Selectbox'

import { AppContext } from '../scripts/AppContext'
import { DataContext } from '../scripts/DataContext'


function AdminsScreen() {

    const { setLoading } = useContext(AppContext)
    const { admins, setAdmins, adminRoles } = useContext(DataContext)

    const [alertMessage, setAlertMessage] = useState(null)

    const [addModal, setAddModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [editID, setEditID] = useState(null)
    const [addInputs, setAddInputs] = useState(null)
    const [editInputs, setEditInputs] = useState(null)

    
    function handleAddAdmin(){
        setAddModal(true)
        setAddInputs({
            konto: 1,
            role: 1,
            vorname: '',
            nachname: '',
            email: '',
            password: '',
            password_confirmation: '',
        })
    }

    function saveAddAdmin(){

        setLoading(true)
        
        authAxios.post('admin', addInputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Erstellt!' })
                setAddModal(false)
                setAdmins([...admins, response.data.admin])
            }

        })
        .catch(error => {
            console.log(error)
        })

    }


    function handleEditAdmin(id){
        setEditModal(true)
        setEditID(id)
        let admin = admins.find(admin => admin.id === id)
        setEditInputs({
            konto: admin.konto,
            role: admin.role,
            vorname: admin.vorname,
            nachname: admin.nachname,
            email: admin.email,
        })
    }

    function saveEditAdmin(){

        setLoading(true)
        
        authAxios.put('admin/' + editID, editInputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setEditModal(false)
                setAdmins(admins.map(admin => admin.id === response.data.admin.id ? response.data.admin : admin))
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }


    function handleDeleteAdmin(){
        
        let q = window.confirm('Diesen Benutzer wirklich löschen?')
        if(q){

            setLoading(true)
            
            authAxios.delete('admin/' + editID)
            .then(response => {
    
                setLoading(false)
    
                if(response.data.type === 'error'){
                    setAlertMessage({ type: response.data.type, message: response.data.message })
                }else{
                    setAlertMessage({ type: response.data.type, message: response.data.message })
                    setEditModal(false)
                    setAdmins(admins.filter(admin => admin.id !== editID))
                }
    
            })
            .catch(error => {
                console.log(error.response.data)
            })

        }

    }

    return (
        <Scrollbars autoHide={true} autoHideDuration={250} autoHideTimeout={500} className="page">
            <div className="page-content">

                <Alert data={alertMessage} />

                <Window name="adminUserList" title={admins.length + ' Benutzer'} actions={
                    <div style={{marginLeft:'auto'}}>
                        <button onClick={e => handleAddAdmin()}><FontAwesomeIcon icon={faPlus} /> Benutzer erstellen</button>
                    </div>
                }>

                    <Table name="tableAdmins" data={admins} headers={[
                        {
                            field: 'konto',
                            label: '',
                            type: 'adminAccount',
                            size: 'xxs',
                        },
                        {
                            field: 'role',
                            label: 'Rolle',
                            type: 'adminRole',
                            size: 'm',
                        },
                        {
                            field: 'vorname',
                            label: 'Vorname',
                            type: 'text',
                            size: 'm',
                        },
                        {
                            field: 'nachname',
                            label: 'Nachname',
                            type: 'text',
                            size: 'm',
                        },
                        {
                            field: 'email',
                            label: 'E-Mail',
                            type: 'text',
                            size: 'l',
                        },
                    ]} actions={{
                        edit: true,
                    }} onEdit={handleEditAdmin} />

                </Window>

                { addModal &&
                    <Modal title="Benutzer erstellen" classes={['small']} toggle={setAddModal} actions={
                        <div className="modal-actions">
                            <div style={{marginLeft:'auto'}}>
                                <button className="cancelButton" onClick={e => setAddModal(false)}>Abbrechen</button>
                                <button className="saveButton" onClick={e => saveAddAdmin()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                            </div>
                        </div>
                    }>
                        
                        <div className="row">
                            <div className="col">
                                <label>Rolle<br />
                                <Selectbox options={adminRoles.map(role => {
                                    return { label: role.name, value: role.id }
                                })} name="role" state={addInputs} setState={setAddInputs} />
                                </label>
                            </div>
                            <div className="col">
                                <label>E-Mail<br />
                                <input type="email" value={addInputs.email} onChange={e => setAddInputs({...addInputs, email: e.target.value})} />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Vorname<br />
                                <input type="text" value={addInputs.vorname} onChange={e => setAddInputs({...addInputs, vorname: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Nachname<br />
                                <input type="text" value={addInputs.nachname} onChange={e => setAddInputs({...addInputs, nachname: e.target.value})} />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Passwort<br />
                                <input type="password" autoComplete="new-password" value={addInputs.password} onChange={e => setAddInputs({...addInputs, password: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Passwort wiederholen<br />
                                <input type="password" autoComplete="new-password" value={addInputs.password_confirmation} onChange={e => setAddInputs({...addInputs, password_confirmation: e.target.value})} />
                                </label>
                            </div>
                        </div>
                        <small>Passwortanforderungen: min. 8 Zeichen, davon min. 1 Buchstabe, 1 Zahl und 1 Sonderzeichen</small>

                    </Modal>
                }

                { editModal &&
                    <Modal title="Benutzer bearbeiten" classes={['small']} toggle={setEditModal} actions={
                        <div className="modal-actions">
                            <button className="deleteLink" onClick={e => handleDeleteAdmin()}>Benutzer löschen</button>
                            <div style={{marginLeft:'auto'}}>
                                <button className="cancelButton" onClick={e => setEditModal(false)}>Abbrechen</button>
                                <button className="saveButton" onClick={e => saveEditAdmin()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                            </div>
                        </div>
                    }>
                        <div className="row">
                            <div className="col">
                                <label className="checkContainer">
                                    <input type="checkbox" checked={editInputs.konto} onChange={e => setEditInputs({...editInputs, konto: !editInputs.konto})} /> <span>Konto aktiv</span>
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Rolle<br />
                                <Selectbox options={adminRoles.map(role => {
                                    return { label: role.name, value: role.id }
                                })} name="role" state={editInputs} setState={setEditInputs} />
                                </label>
                            </div>
                            <div className="col">
                                <label>E-Mail<br />
                                <input type="email" value={editInputs.email} onChange={e => setEditInputs({...editInputs, email: e.target.value})} />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Vorname<br />
                                <input type="text" value={editInputs.vorname} onChange={e => setEditInputs({...editInputs, vorname: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Nachname<br />
                                <input type="text" value={editInputs.nachname} onChange={e => setEditInputs({...editInputs, nachname: e.target.value})} />
                                </label>
                            </div>
                        </div>

                    </Modal>
                }

            </div>
        </Scrollbars>
    )

}

export default AdminsScreen