import React, { useState, useContext, useEffect } from 'react'
import '../../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPen, faCheck, faComment, faMapPin } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import { updatePref, getPref } from '../../scripts/Functions'
import { AppContext } from '../../scripts/AppContext'
import { DataContext } from '../../scripts/DataContext'
import { UserContext } from '../../scripts/UserContext'
import { authAxios } from '../../scripts/Axios'

import Window from '../../components/Window'
import Table from '../../components/Table'
import Modal from '../../components/Modal'
import Selectbox from '../../components/Selectbox'
import Alert from '../../components/Alert'

function TasksNotesScreen({user, setUser}) {

    const { setLoading } = useContext(AppContext)
    const { admins } = useContext(DataContext)
    const { user: me, checkScope } = useContext(UserContext)

    const [alertMessage, setAlertMessage] = useState(null)

    const [addTaskModal, setAddTaskModal] = useState(false)
    const [addTaskInputs, setAddTaskInputs] = useState(null)
    const [showMyTasks, setShowMyTasks] = useState(false)
    const [showFinishedTasks, setShowFinishedTasks] = useState(false)
    const [myTasks, setMyTasks] = useState([])

    const [editTaskModal, setEditTaskModal] = useState(false)
    const [editTaskID, setEditTaskID] = useState(null)
    const [editTaskInputs, setEditTaskInputs] = useState(null)

    const [taskActivities, setTaskActivites] = useState([])
    const [addTaskActivity, setAddTaskActivity] = useState(false)
    const [activityText, setActivityText] = useState('')

    const [addNotice, setAddNotice] = useState('')
    const [addNoticeModal, setAddNoticeModal] = useState(false)

    const [editNotice, setEditNotice] = useState('')
    const [editNoticeID, setEditNoticeID] = useState(null)
    const [editNoticeModal, setEditNoticeModal] = useState(false)


    useEffect(() => {

        setMyTasks(user.tasks.filter(task => {
            if(!Array.isArray(task.bearbeiter)){
                return task.bearbeiter === me.id
            }else{
                return task.bearbeiter.includes(me.id)
            }
        }))
        setTableOptionsPref()

    }, [user.tasks])

    
    function handleAddTask(){
        setAddTaskModal(true)
        setAddTaskInputs({
            user_id: user.id,
            zieldatum: null,
            bearbeiter: null,
            titel: '',
            beschreibung: '',
            priority: 0,
            status: 0,
        })
    }

    function saveAddTask(){

        setLoading(true)
        
        authAxios.post('task', addTaskInputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Erstellt!' })
                setAddTaskModal(false)
                setUser({
                    ...user,
                    tasks: [
                        response.data.task,
                        ...user.tasks,
                    ],
                })
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }


    function handleEditTask(id){
        setEditTaskModal(true)
        setEditTaskID(id)
        let task = user.tasks.find(task => task.id === id)
        setEditTaskInputs({
            user_id: task.user_id,
            zieldatum: task.zieldatum,
            bearbeiter: task.bearbeiter,
            titel: task.titel,
            beschreibung: task.beschreibung,
            priority: task.priority,
            status: task.status,
        })
        if(task.activities) setTaskActivites(task.activities)
        else setTaskActivites([])
        setActivityText('')
    }

    function saveEditTask(){

        setLoading(true)
        
        authAxios.put('task/' + editTaskID, editTaskInputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setEditTaskModal(false)
                setUser({
                    ...user,
                    tasks: user.tasks.map(task => task.id === response.data.task.id ? response.data.task : task)
                })
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    function handleDeleteTask(){
        
        let q = window.confirm('Diese Aufgabe wirklich löschen?')
        if(q){

            setLoading(true)
            
            authAxios.delete('task/' + editTaskID)
            .then(response => {
    
                setLoading(false)
    
                if(response.data.type === 'error'){
                    setAlertMessage({ type: response.data.type, message: response.data.message })
                }else{
                    setAlertMessage({ type: response.data.type, message: response.data.message })
                    setEditTaskModal(false)
                    setUser({
                        ...user,
                        tasks: user.tasks.filter(task => task.id !== editTaskID)
                    })
                }
    
            })
            .catch(error => {
                console.log(error.response.data)
            })

        }

    }

    function saveTaskActivity(){
        setLoading(true)
        
        authAxios.post('task-activity', {
            task_id: editTaskID,
            type: 'text',
            status: editTaskInputs.status,
            beschreibung: activityText,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setActivityText('')
                setAddTaskActivity(false)
                setTaskActivites([
                    ...taskActivities,
                    response.data.activity,
                ])
                setUser({
                    ...user,
                    tasks: user.tasks.map(task => {
                        if(task.id === response.data.activity.task_id){
                            return {
                                ...task,
                                activities: [
                                    ...task.activities,
                                    response.data.activity,
                                ]
                            }
                        }else return task
                    })
                })
            }

        })
        .catch(error => {
            console.log(error)
        })
    }



    function handleAddNotice(){
        setAddNotice('')
        setAddNoticeModal(true)
    }

    function saveAddNotice(){
        
        setLoading(true)
        
        authAxios.post('notice', {
            user_id: user.id,
            comment: addNotice,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{

                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setAddNoticeModal(false)
                
                setUser({
                    ...user,
                    notices: [
                        response.data.notice,
                        ...user.notices,
                    ],
                })

            }

        })
        .catch(error => {
            console.log(error.response.data)
        })
        
    }

    function handleEditNotice(id){
        setEditNoticeID(id)
        let notice = user.notices.find(notice => notice.id === id)
        setEditNotice(notice.comment)
        setEditNoticeModal(true)
    }

    function saveEditNotice(){
        
        setLoading(true)
        
        authAxios.put('notice/' + editNoticeID, {
            comment: editNotice,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{

                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setEditNoticeModal(false)
                
                setUser({
                    ...user,
                    notices: user.notices.map(notice => notice.id === editNoticeID ? response.data.notice : notice),
                })

            }

        })
        .catch(error => {
            console.log(error.response.data)
        })
        
    }

    function handlePinNotice(id){

        setLoading(false)

        authAxios.put('notice/' + id + '/pin')
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Notiz angepinnt.' })
                setUser({
                    ...user,
                    notices: response.data.notices,
                })
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })
    }
    
    function handleDeleteNotice(){
        
        let q = window.confirm('Dieses Notiz wirklich löschen?')
        if(q){

            setLoading(true)
            
            authAxios.delete('notice/' + editNoticeID)
            .then(response => {
    
                setLoading(false)
    
                if(response.data.type === 'error'){
                    setAlertMessage({ type: response.data.type, message: response.data.message })
                }else{
                    setAlertMessage({ type: response.data.type, message: 'Gelöscht!' })
                    setEditNoticeModal(false)
                    setUser({
                        ...user,
                        notices: user.notices.filter(notice => notice.id !== editNoticeID),
                    })
                }
    
            })
            .catch(error => {
                console.log(error.response.data)
            })

        }

    }
    
        
    async function setTableOptionsPref(){        
        let tableOptionsPref = await getPref('tableUserTasksOptions')
        if(tableOptionsPref !== null){
            setShowMyTasks(tableOptionsPref.showMyTasks)
            setShowFinishedTasks(tableOptionsPref.showFinishedTasks)
        }
    }

    function changeShowMyTasks(){
        let state = !showMyTasks
        setShowMyTasks(state)
        updatePref('tableUserTasksOptions', {
            showMyTasks: state,
            showFinishedTasks: showFinishedTasks,
        })
    }

    function changeShowFinishedTasks(){
        let state = !showFinishedTasks
        setShowFinishedTasks(state)
        updatePref('tableUserTasksOptions', {
            showMyTasks: showMyTasks,
            showFinishedTasks: state,
        })
    }

    return (
        <div className="page-content">

            <Alert data={alertMessage} />

            <div className="window-row">

                <div className="col">

                    <Window name="userTasks" title="Aufgaben" actions={
                        <div style={{marginLeft:'auto'}}>
                            { checkScope('createTask', { 'assigned_to': user.assigned_to }) ? (
                                <button onClick={e => handleAddTask()}><FontAwesomeIcon icon={faPlus} /> Aufgabe erstellen</button>
                            ) : null }
                        </div>
                    }>

                        <Table name="tableUserTasks" data={showMyTasks
                            ? (showFinishedTasks ? myTasks : myTasks.filter(t => t.status !== 1))
                            : (showFinishedTasks ? user.tasks : user.tasks.filter(t => t.status !== 1))
                        } headers={[
                            {
                                field: 'status',
                                label: 'Status',
                                type: 'taskstatus',
                                size: 's',
                            },
                            {
                                field: 'titel',
                                label: 'Titel',
                                type: 'text',
                                size: 'xl',
                            },
                            {
                                field: 'zieldatum',
                                label: 'Erledigt bis',
                                type: 'date',
                                size: 's',
                            },
                            {
                                field: 'bearbeiter',
                                label: 'Bearbeiter',
                                type: 'adminlist',
                                size: 'm',
                            },
                            {
                                field: 'von',
                                label: 'Ersteller',
                                type: 'admin',
                                size: 'm',
                            },
                        ]}
                        onEdit={handleEditTask}
                        editType="tasks"
                        currentUser={user}
                        footerContent={
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <label className="checkContainer">
                                    <input type="checkbox" checked={showMyTasks} onChange={changeShowMyTasks} /> <span>Nur meine Aufgaben anzeigen</span>
                                </label>
                                <label className="checkContainer" style={{marginLeft: 20}}>
                                    <input type="checkbox" checked={showFinishedTasks} onChange={changeShowFinishedTasks} /> <span>Erledigte Aufgaben anzeigen</span>
                                </label>
                            </div>
                        } />

                    </Window>

                </div>
            </div>

            <div className="window-row">
                <div className="col">

                    <Window name="userNotices" title="Notizen" actions={
                        <div style={{marginLeft:'auto'}}>
                            { checkScope('createNote', { 'assigned_to': user.assigned_to }) ? (
                                <button onClick={e => handleAddNotice()}><FontAwesomeIcon icon={faPlus} /> Notiz erstellen</button>
                            ) : null }
                        </div>
                    }>
                        
                        {user.notices.length > 0 ?
                            user.notices.map(notice => {
                                let admin = admins.find(admin => admin.id === notice.admin_id)
                                return (
                                    <div key={notice.id} className={'notice' + (notice.pinned ? ' pinned' : '')}>
                                        <div className="notice_comment">{notice.comment}</div>
                                        <div className="notice_meta">
                                            <div>am {moment(notice.updated_at).format('DD.MM.YYYY')} von {admin ? (admin.vorname + ' ' + admin.nachname) : '[User gelöscht]'}</div>
                                            <div style={{ marginLeft: 'auto' }}>
                                                { checkScope('pinNote', { 'created_by': notice.admin_id, 'assigned_to': user.assigned_to }) && (
                                                    <button className="outlineButton pinButton" style={{ marginRight: 5 }} onClick={e => handlePinNotice(notice.id)}><FontAwesomeIcon icon={faMapPin} /> {notice.pinned ? 'Pin entfernen' : 'Anpinnen'}</button>
                                                )}
                                                { checkScope('editNote', { 'created_by': notice.admin_id, 'assigned_to': user.assigned_to }) && (
                                                    <button className="outlineButton" onClick={e => handleEditNotice(notice.id)}><FontAwesomeIcon icon={faPen} /> Bearbeiten</button>
                                                )}
                                                </div>
                                        </div>
                                    </div>
                                )
                            })
                            : <div className="nohits">Keine Notizen vorhanden.</div>
                        }


                    </Window>

                </div>

            </div>


            { addTaskModal &&
                <Modal title="Aufgabe erstellen" toggle={setAddTaskModal} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setAddTaskModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveAddTask()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>
                    
                    <div className="row">
                        <div className="col">
                            <label>Status<br />
                            <Selectbox options={[
                                { label: 'Offen', value: 0 },
                                { label: 'In Bearbeitung', value: 2 },
                                { label: 'Erledigt', value: 1 },
                            ]} name="status" state={addTaskInputs} setState={setAddTaskInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Priorität<br />
                            <Selectbox options={[
                                { label: 'Normal', value: 0 },
                                { label: 'Hoch', value: 1 },
                            ]} name="priority" state={addTaskInputs} setState={setAddTaskInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Zu erledigen bis<br />
                            <input type="date" value={addTaskInputs.zieldatum} onChange={e => setAddTaskInputs({...addTaskInputs, zieldatum: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Titel<br />
                            <input type="text" value={addTaskInputs.titel} onChange={e => setAddTaskInputs({...addTaskInputs, titel: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Beschreibung<br />
                            <textarea value={addTaskInputs.beschreibung} onChange={e => setAddTaskInputs({...addTaskInputs, beschreibung: e.target.value})}></textarea>
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Bearbeiter<br />
                            <Selectbox options={admins.map(admin => {
                                return { label: admin.vorname + ' ' + admin.nachname, value: admin.id }
                            })} name="bearbeiter" state={addTaskInputs} setState={setAddTaskInputs} multiple={true} />
                            </label>
                        </div>
                    </div>

                </Modal>
            }

            { editTaskModal &&
                <Modal title="Aufgabe bearbeiten" toggle={setEditTaskModal} actions={
                    <div className="modal-actions">
                        { checkScope('deleteTask', { 'von': user.von, 'assigned_to': user.assigned_to }) ? (
                            <button className="deleteLink" onClick={e => handleDeleteTask()}>Aufgabe löschen</button>
                        ) : null }
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setEditTaskModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveEditTask()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>
                    
                    <div className="row">
                        <div className="col">
                            <label>Status<br />
                            <Selectbox options={[
                                { label: 'Offen', value: 0 },
                                { label: 'In Bearbeitung', value: 2 },
                                { label: 'Erledigt', value: 1 },
                            ]} name="status" state={editTaskInputs} setState={setEditTaskInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Priorität<br />
                            <Selectbox options={[
                                { label: 'Normal', value: 0 },
                                { label: 'Hoch', value: 1 },
                            ]} name="priority" state={editTaskInputs} setState={setEditTaskInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Zu erledigen bis<br />
                            <input type="date" value={editTaskInputs.zieldatum} onChange={e => setEditTaskInputs({...editTaskInputs, zieldatum: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Titel<br />
                            <input type="text" value={editTaskInputs.titel} onChange={e => setEditTaskInputs({...editTaskInputs, titel: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Beschreibung<br />
                            <textarea value={editTaskInputs.beschreibung} onChange={e => setEditTaskInputs({...editTaskInputs, beschreibung: e.target.value})}></textarea>
                            </label>
                        </div>
                    </div>
                    <div>
                        
                        { taskActivities.map(activity => {
                            let admin = admins.find(a => a.id === activity.von)
                            let date = moment(activity.created_at).format('DD.MM.YYYY HH:mm')
                            let stateLabel = ''
                            if(activity.status === 0) stateLabel = 'Offen'
                            else if(activity.status === 1) stateLabel = 'Erledigt'
                            else if(activity.status === 2) stateLabel = 'In Bearbeitung'

                            return activity.type === 'text' ? (
                                <div key={activity.id} className="task-activity">
                                    <div className="row task-activity-head">
                                        <div className="col">{admin.vorname + ' ' + admin.nachname}</div>
                                        <div className="col">{date}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">{activity.beschreibung}</div>
                                    </div>
                                </div>
                            ) : (
                                <div key={activity.id} className="task-activity">
                                    <div className="row task-activity-head">
                                        <div className="col" style={{flexGrow: 0, whiteSpace: 'nowrap'}}>{admin.vorname + ' ' + admin.nachname}</div>
                                        <div className="col" style={{fontWeight: 400}}>hat den Status auf <span className={'taskstate' + activity.status}>{stateLabel}</span> gestellt.</div>
                                        <div className="col">{date}</div>
                                    </div>
                                </div>
                            )
                        })}

                        { !addTaskActivity ? (
                            <div className="table-actions">
                                <button onClick={() => setAddTaskActivity(true)}><FontAwesomeIcon icon={faComment} /> Antworten</button>
                            </div>
                        ):(
                            <div style={{marginTop: 5}}>
                                <label>Antwort<br />
                                    <textarea value={activityText} onChange={e => setActivityText(e.target.value)}></textarea>
                                </label>
                                <div className="table-actions">
                                    <button onClick={() => setAddTaskActivity(false)}>Abbrechen</button>
                                    <button onClick={saveTaskActivity} style={{marginLeft: 5}}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                                </div>
                            </div>
                        )}
                        
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Bearbeiter<br />
                            <Selectbox options={admins.map(admin => {
                                return { label: admin.vorname + ' ' + admin.nachname, value: admin.id }
                            })} name="bearbeiter" state={editTaskInputs} setState={setEditTaskInputs} multiple={true} />
                            </label>
                        </div>
                    </div>

                </Modal>
            }


            { addNoticeModal &&
                <Modal title="Notiz erstellen" toggle={setAddNoticeModal} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setAddNoticeModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveAddNotice()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>                    
                    
                    <div className="row">
                        <div className="col">
                            <label>Notiz<br />
                            <textarea value={addNotice} onChange={e => setAddNotice(e.target.value)}></textarea>
                            </label>
                        </div>
                    </div>

                </Modal>
            }


            { editNoticeModal &&
                <Modal title="Notiz bearbeiten" toggle={setEditNoticeModal} actions={
                    <div className="modal-actions">
                        { checkScope('deleteTask', { 'von': user.von, 'assigned_to': user.assigned_to }) ? (
                            <button className="deleteLink" onClick={e => handleDeleteNotice()}>Notiz löschen</button>
                        ) : null }
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setEditNoticeModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveEditNotice()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>                    
                    
                    <div className="row">
                        <div className="col">
                            <label>Notiz<br />
                            <textarea value={editNotice} onChange={e => setEditNotice(e.target.value)}></textarea>
                            </label>
                        </div>
                    </div>

                </Modal>
            }

        </div>
    )

}

export default TasksNotesScreen