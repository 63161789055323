import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faSort, faSortUp, faSortDown, faPen, faEyeSlash, faEye, faEdit } from '@fortawesome/free-solid-svg-icons'
import { faAndroid, faApple, faInternetExplorer } from '@fortawesome/free-brands-svg-icons'
import moment from 'moment'

import { ContactType, ContractType, ProfileField, updatePref, getPref, Country } from '../scripts/Functions'
import { DataContext } from '../scripts/DataContext'
import { UserContext } from '../scripts/UserContext'

import AccountStatus from './AccountStatus'
import UserStatus from './UserStatus'
import CareStatus from './CareStatus'
import TaskStatus from './TaskStatus'
import RequestStatus from './RequestStatus'
import ConfirmStatus from './ConfirmStatus'
import LeistungsnachweisStatus from './LeistungsnachweisStatus'
import CareLevel from './CareLevel'
import Rating from './Rating'

function Table({name, data, headers, onEdit, editType = null, editIdField = null, editText, editIcon = faPen, onAction, actionIcon = faEye, currentUser = null, perPage, footerContent}) {

    const history = useHistory()

    const { admins, adminRoles, users } = useContext(DataContext)
    const { checkScope } = useContext(UserContext)

    const [items, setItems] = useState([])
    const [pageItems, setPageItems] = useState([])
    const [page, setPage] = useState(1)
    const [ppp, setPpp] = useState(perPage ? perPage : 10)
    const [orderBy, setOrderBy] = useState(null)
    const [order, setOrder] = useState(0)
    const pages = Math.ceil(items.length / ppp)

    useEffect(() => {
        if(data) setItems(data)
        setPage(1)
        setTablePref()
    }, [data])

    useEffect(() => {
        if(data){
            setPageItems(items.filter((el, index) => index + 1 > page * ppp - ppp && index + 1 <= page * ppp))
            
            if(items.length){
                let maxPages = Math.ceil(items.length / ppp)
                if(page > maxPages){
                    setPage(maxPages)
                }
            }
        }
    }, [items, page, ppp])


    function changePpp(val){  
        setPpp(val)
        updatePref(name, {
            ppp: val,
            page: page,
            orderby: orderBy,
            order: order,
        })
    }

    function changePage(val){
        setPage(val)
        updatePref(name, {
            ppp: ppp,
            page: val,
            orderby: orderBy,
            order: order,
        })
    }
    
    async function setTablePref(){        
        let tablePref = await getPref(name)
        if(tablePref !== null){
            setPpp(tablePref.ppp ? parseInt(tablePref.ppp) : ppp)
            setPage(tablePref.page ? parseInt(tablePref.page) : 1)
            //setOrderBy(tablePref.orderby)
            //setOrder(tablePref.order)
        }
    }


    function paginateItems() {
        let pagination = []

        for(let p = 1; p <= pages; p++){
            if(p === 1 || (p >= page - 2 && p <= page + 2) || p === pages){
                let isActive = p === page ? 'active' : ''
                pagination.push(
                    <li key={p} className={'noselect '+isActive} onClick={e => changePage(p)}>{p}</li>
                )
            }
        }

        let disabledPrevButton = <li key="-1" className="disabled"><FontAwesomeIcon icon={faChevronLeft} /></li>
        let prevButton = <li key="-1" onClick={e => setPage(page-1)}><FontAwesomeIcon icon={faChevronLeft} /></li>
        let disabledNextButton = <li key="999999" className="disabled"><FontAwesomeIcon icon={faChevronRight} /></li>
        let nextButton = <li key="999999" onClick={e => setPage(page+1)}><FontAwesomeIcon icon={faChevronRight} /></li>

        pagination.unshift(page > 1 ? prevButton : disabledPrevButton)
        pagination.push(page < pages ? nextButton : disabledNextButton)

        return pagination
    }

    function sortItems(header)
    {
        let newOrder = order === 0 || order === -1 ? 1 : -1
        if(orderBy !== header.field){ newOrder = 1 }

        setOrderBy(header.field)
        setOrder(newOrder)
        
        updatePref(name, {
            ppp: ppp,
            page: page,
            orderby: header.field,
            order: newOrder,
        })

        if(header.profile){
            items.sort(sortProfileFunction(header.field, newOrder))
        }
        else if(['user', 'recipient'].includes(header.type)){
            items.sort(sortUserFunction(header.field, newOrder))
        }
        else{
            items.sort(sortFunction(header.field, newOrder))
        }

        setItems([...items]) // force update
    }

    function sortUserFunction(newOrderBy, newOrder) {
        return function (a, b) {
            let userA = users.find(u => u.id === a[newOrderBy])
            let userB = users.find(u => u.id === b[newOrderBy])
            if(!userA || !userB) return -1
            let aVar = userA.nachname ? userA.nachname : ''
            let bVar = userB.nachname ? userB.nachname : ''
            let result = (aVar < bVar) ? -1 : (aVar > bVar) ? 1 : 0
            return result * newOrder
        }
    }

    function sortProfileFunction(newOrderBy, newOrder){
        return function (a, b) {
            let aVar
            if(a['profiles'].length){
                aVar = a['profiles'][0][newOrderBy] ? a['profiles'][0][newOrderBy] : ''
            }else{
                aVar = ''
            }

            let bVar
            if(b['profiles'].length){
                bVar = b['profiles'][0][newOrderBy] ? b['profiles'][0][newOrderBy] : ''
            }else{
                bVar = ''
            }

            let result = (aVar < bVar) ? -1 : (aVar > bVar) ? 1 : 0
            return result * newOrder
        }
    }

    function sortFunction(newOrderBy, newOrder){
        return function (a, b) {
            let aVar = a[newOrderBy] ? a[newOrderBy] : ''
            let bVar = b[newOrderBy] ? b[newOrderBy] : ''
            let result = (aVar < bVar) ? -1 : (aVar > bVar) ? 1 : 0
            return result * newOrder
        }
    }

    function Row({children, highlightClass}){
        return (
            <div className={'table-row' + (highlightClass ? ' '+highlightClass : '')}>
                {children}
            </div>
        )
    }

    function Column({header, values}){
        return (
            <div className={ 'table-col ' + header.size } style={ (header.size !== 'xl' && header.size !== 'xxl') ? {textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'} : {}}>
                { fieldType(header, values) }
            </div>
        )
    }

    function ProfileColumn({header, profiles}){
        return (
            <div className={ 'table-col ' + header.size }>
                { profiles.map((profile, index) => {
                    return (
                        <div key={index}>
                            { fieldType(header, profile) }
                        </div>
                    )
                })}
            </div>
        )        
    }
    
    function fieldType(header, values){
        
        let value = header.field && (values[header.field] || values[header.field] === 0) ? values[header.field] : null

        switch(header.type)
        {
            case 'account':
                return <AccountStatus value={value} />

            case 'adminAccount':
                return <AccountStatus value={value === 1 ? 4 : 0} />

            case 'status':
                return <UserStatus value={value} />

            case 'carestatus':
                return <CareStatus value={value} />

            case 'taskstatus':
                return <TaskStatus value={value} />

            case 'requeststatus':
                return <RequestStatus value={values['timedout'] === 1 ? 3 : value} />

            case 'confirmstatus':
                if(value) return <ConfirmStatus values={values} />

            case 'leistungsnachweisstatus':
                return <LeistungsnachweisStatus values={values} />

            case 'carelevel':
                return <CareLevel value={value} />

            case 'rating':
                return <Rating value={value} />

            case 'profile':
                return ProfileField(value)

            case 'bool':
                return value ? 'Ja' : 'Nein'

            case 'date':
                let date = moment(value)
                if(!date.isValid()) return '-'
                else if(date.isSame(moment(), 'day')) date = 'Heute'
                else date = moment(date).format('DD.MM.YYYY')
                return date

            case 'datetime':
                let date2 = moment(value)
                let time = moment(value).format('HH:mm')
                if(!date2.isValid()) return '-'
                else if(date2.isSame(moment(), 'day')) date2 = 'Heute'
                else date2 = moment(date2).format('DD.MM.YYYY')
                return date2 + ' ' + time

            case 'monthYear':
                let date3 = moment(value)
                return date3.format('MMMM YYYY')

            case 'age':
                return value ? moment().diff(moment(value), 'years') : '-'

            case 'contactType':
                return value ? ContactType(value) : ''

            case 'userRole':
                return value === 1 ? 'BK' : 'PB'

            case 'adminRole':
                return value ? adminRoles.find(role => role.id == value).name : ''

            case 'admin':
                let admin = admins.find(admin => admin.id === parseInt(value))
                return admin ? admin.vorname + ' ' + admin.nachname : 'System'

            case 'adminlist':
                let list = ''
                if(!Array.isArray(value)){
                    let admin = admins.find(admin => admin.id === parseInt(value))
                    return admin ? admin.vorname + ' ' + admin.nachname : '-'
                }else{
                    value.map((user_id, index) => {
                        let admin = admins.find(admin => admin.id === parseInt(user_id))
                        list += admin ? admin.vorname + ' ' + admin.nachname : '?'
                        if(value.length > index + 1) list += ', '
                    })
                    return list
                }

            case 'user':
                let user = users.find(user => user.id === parseInt(value))
                if(user && checkScope('viewUser', { assigned_to: user.assigned_to })){
                    return user ? <div data-custom-sort className="link" onClick={() => history.push('/user/' + user.id)}>{user.fullname}</div> : '-'
                }else{
                    return user ? <div>{user.fullname}</div> : '-'
                }

            case 'recipient':
                let user2 = users.find(user => user.id === parseInt(value))
                return user2 ? (
                    <div className="link" onClick={() => history.push('/user/' + user2.id)}>{user2.fullname}</div>
                ) : 'Mehrere Empfänger'

            case 'email':
                let user3 = users.find(user => user.id === parseInt(values.id))
                return <div className="link" onClick={() => history.push('/user/' + user3.id + '/communication')}>{value}</div>

            case 'contactRecipient':
                if(!currentUser) return ''
                if(values.empfaenger === 'user' || !values.empfaenger_id){
                    return currentUser.profiles[0].fullname
                }else{
                    let contact = currentUser.contacts.find(c => c.id === parseInt(values.empfaenger_id))
                    return contact ? contact.fullname : '-'
                }

            case 'transfertype':
                switch (value) {
                    case 'car': return 'Auto'
                    case 'bus': return 'Bus'
                    case 'train': return 'Zug'
                    case 'plane': return 'Flugzeug'
                    default: return '-'
                }

            case 'longtext':
                let maxChars = 80
                return <div style={{ lineHeight: '16px' }}>
                    {(value && value.length > maxChars) ? value.substring(0, maxChars) + ' ...' : value }
                </div>

            case 'multitext':
                return <div style={{whiteSpace:'break-spaces'}}>{value}</div>

            case 'attachments':
                if(Array.isArray(value)) return value.length ? 'Ja' : 'Nein'
                else return 'Nein'

            case 'payload':
                delete value.mailable.data.nachricht
                return JSON.stringify(value.mailable)

            case 'score':
                return <div className="score">{value ? value + '%' : '-'}</div>

            case 'visibility':
                return value === 1 ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} color="#d6e1e9" />

            case 'origin':
                switch(value){
                    case 'android': return <FontAwesomeIcon icon={faAndroid} style={{fontSize: 20, marginBottom: -4, marginLeft: -1}} color="#9cba36" title="Android" />
                    case 'ios': return <FontAwesomeIcon icon={faApple} style={{fontSize: 26, marginBottom: -5}} color="#7B919D" title="iOS" />
                    case 'web': return <FontAwesomeIcon icon={faInternetExplorer} style={{fontSize: 20, marginBottom: -2, marginLeft: -1}} color="#64afd9" title="Web" />
                    default: return <FontAwesomeIcon icon={faEdit} style={{fontSize: 18, marginBottom: -2, marginLeft: 2}} color="#A1B2BC" title="Manuell" />
                }

            /* case 'confirmation':
                return <ConfirmStatus message={values} /> */

            case 'contract':
                return <ContractType contract={value} />

            case 'country':
                return value ? <Country iso={value} /> : null
            
            case 'hidden':
                return ''

            default:
                return value
        }
    }

    function makeRows(el)
    {     
        let hClass = null
        if(el.system) hClass = 'highlight'
        else if(el.priority) hClass = 'priority'

        return (
            <Row key={el.id} highlightClass={hClass}>

                { headers.map((header, index) => {
                    if(header.type == 'hidden') return null
                    else return !header.profile ? <Column key={index} header={header} values={el} /> : <ProfileColumn key={index} header={header} profiles={el.profiles} />                
                }) }

                { (onEdit || onAction) &&
                    <div className="table-col table-actions">
                        { onAction && checkScope('editUser', { 'assigned_to': el.assigned_to }) ?
                            <button onClick={e => onAction(editIdField ? el[editIdField] : el.id)} disabled={ el.status === 3 ? true : false }>
                                <FontAwesomeIcon icon={actionIcon} /> { 'Senden' }
                            </button>
                        : null }
                        { onEdit && editType === null && checkScope('viewUser', { 'assigned_to': el.assigned_to, 'bearbeiter': el.bearbeiter, 'von': el.von }) ?
                            <button onClick={e => onEdit(editIdField ? el[editIdField] : el.id)}>
                                <FontAwesomeIcon icon={editIcon} /> { editText ? editText : 'Bearbeiten' }
                            </button>
                        : null }
                        { onEdit && editType == 'cares' && checkScope('editCares', { 'assigned_to': currentUser.assigned_to, 'von': el.von }) ?
                            <button onClick={e => onEdit(editIdField ? el[editIdField] : el.id)}>
                                <FontAwesomeIcon icon={editIcon} /> { editText ? editText : 'Bearbeiten' }
                            </button>
                        : null }
                        { onEdit && editType == 'tasks' && checkScope('editTask', { 'assigned_to': currentUser.assigned_to, 'von': el.von, 'bearbeiter': el.bearbeiter }) ?
                            <button onClick={e => onEdit(editIdField ? el[editIdField] : el.id)}>
                                <FontAwesomeIcon icon={editIcon} /> { editText ? editText : 'Bearbeiten' }
                            </button>
                        : null }
                        { onEdit && editType == 'notes' && checkScope('editNote', { 'assigned_to': currentUser.assigned_to, 'von': el.von }) ?
                            <button onClick={e => onEdit(editIdField ? el[editIdField] : el.id)}>
                                <FontAwesomeIcon icon={editIcon} /> { editText ? editText : 'Bearbeiten' }
                            </button>
                        : null }
                    </div>
                }

            </Row>
        )
    }

    return (
        <div className="table">

            <div className="table-head">            
                {
                headers.map((header, index) => {
                    return header.type != 'hidden' ? (
                        <div key={index} className={ 'table-col noselect ' + header.size } onClick={e => sortItems(header)}>
                            { header.labelAsIcon ? <FontAwesomeIcon icon={header.labelIcon} /> : header.label}
                            {header.field !== orderBy && <div className="sortIcon"><FontAwesomeIcon icon={faSort} /></div> }
                            {header.field === orderBy && order === 1 && <div className="sortIcon active"><FontAwesomeIcon icon={faSortDown} /></div> }
                            {header.field === orderBy && order === -1 && <div className="sortIcon active"><FontAwesomeIcon icon={faSortUp} /></div> }
                        </div>
                    ) : null
                })
                }
                { onAction && <div className="table-col pseudo"></div> }
                { onEdit && <div className="table-col pseudo"></div> }
            </div>

            <div className="table-body">
                { pageItems.map(el => makeRows(el)) }
                { pageItems.length < 1 &&
                    <div className="nohits">Keine Ergebnisse gefunden.</div>
                }
            </div>

            <div className="table-footer">

                { footerContent }

                <select className="ppp" value={ppp} onChange={e => changePpp(e.target.value)}>
                    <option value="10">10 pro Seite</option>
                    <option value="25">25 pro Seite</option>
                    <option value="50">50 pro Seite</option>
                    <option value="100">100 pro Seite</option>
                </select>
                
                <ul className="pagination">
                    { paginateItems().map(el => el) }
                </ul>

            </div>
            
        </div>
    )

}

export default Table