import React, { useContext, useEffect, useState } from 'react'
import '../App.css'
import { Scrollbars } from 'react-custom-scrollbars'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCheck, faComment } from '@fortawesome/free-solid-svg-icons'
import { authAxios } from '../scripts/Axios'
import { updatePref, getPref } from '../scripts/Functions'
import moment from 'moment'

import Window from '../components/Window'
import Table from '../components/Table'
import Modal from '../components/Modal'
import Selectbox from '../components/Selectbox'
import Alert from '../components/Alert'

import { AppContext } from '../scripts/AppContext'
import { UserContext } from '../scripts/UserContext'
import { DataContext } from '../scripts/DataContext'


function TasksScreen() {

    const { setLoading } = useContext(AppContext)
    const { user, checkScope } = useContext(UserContext)
    const { admins, users } = useContext(DataContext)

    const [alertMessage, setAlertMessage] = useState(null)

    const [tasks, setTasks] = useState([])
    const [myTasks, setMyTasks] = useState([])
    const [displayedTasks, setDisplayedTasks] = useState([])
    const [addModal, setAddModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [editID, setEditID] = useState(null)
    const [addInputs, setAddInputs] = useState(null)
    const [editInputs, setEditInputs] = useState(null)
    const [showMyTasks, setShowMyTasks] = useState(true)
    const [showFinishedTasks, setShowFinishedTasks] = useState(false)

    const [taskActivities, setTaskActivites] = useState([])
    const [addTaskActivity, setAddTaskActivity] = useState(false)
    const [activityText, setActivityText] = useState('')

    useEffect(() => {

        setLoading(true)

        authAxios.get('tasks')
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setTasks(response.data.tasks)
                setTableOptionsPref()
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }, [])

    useEffect(() => {

        setMyTasks(tasks.filter(task => {
            if(!Array.isArray(task.bearbeiter)){
                return task.bearbeiter === user.id
            }else{
                return task.bearbeiter.includes(user.id)
            }
        }))

    }, [tasks])

    useEffect(() => {
        if(showMyTasks){
            if(showFinishedTasks){
                setDisplayedTasks(myTasks)
            }else{
                setDisplayedTasks(myTasks.filter(t => t.status !== 1))
            }
        }else{
            if(showFinishedTasks){
                setDisplayedTasks(tasks)
            }else{
                setDisplayedTasks(tasks.filter(t => t.status !== 1))
            }

        }                    
    }, [myTasks, showMyTasks, showFinishedTasks])

    
    function handleAddTask(){
        setAddModal(true)
        setAddInputs({
            user_id: null,
            zieldatum: null,
            bearbeiter: null,
            titel: '',
            beschreibung: '',
            priority: 0,
            status: 0,
        })
    }

    function saveAddTask(){

        setLoading(true)
        
        authAxios.post('task', addInputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Erstellt!' })
                setAddModal(false)
                setTasks([response.data.task, ...tasks])
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }


    function handleEditTask(id){
        setEditModal(true)
        setEditID(id)
        let task = tasks.find(task => task.id === id)
        setEditInputs({
            user_id: task.user_id,
            zieldatum: task.zieldatum,
            bearbeiter: task.bearbeiter,
            titel: task.titel,
            beschreibung: task.beschreibung,
            priority: task.priority,
            status: task.status,
            von: task.von,
        })
        if(task.activities) setTaskActivites(task.activities)
        else setTaskActivites([])
        setActivityText('')
    }

    function saveEditTask(){

        setLoading(true)
        
        authAxios.put('task/' + editID, editInputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setEditModal(false)
                setTasks(tasks.map(task => task.id === response.data.task.id ? response.data.task : task))
            }

        })
        .catch(error => {
            console.log(error)
        })

    }

    function handleDeleteTask(){
        
        let q = window.confirm('Diese Aufgabe wirklich löschen?')
        if(q){

            setLoading(true)
            
            authAxios.delete('task/' + editID)
            .then(response => {
    
                setLoading(false)
    
                if(response.data.type === 'error'){
                    setAlertMessage({ type: response.data.type, message: response.data.message })
                }else{
                    setAlertMessage({ type: response.data.type, message: response.data.message })
                    setEditModal(false)
                    setTasks(tasks.filter(task => task.id !== editID))
                }
    
            })
            .catch(error => {
                console.log(error.response.data)
            })

        }

    }

    function saveTaskActivity(){
        setLoading(true)
        
        authAxios.post('task-activity', {
            task_id: editID,
            type: 'text',
            status: editInputs.status,
            beschreibung: activityText,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setActivityText('')
                setAddTaskActivity(false)
                setTaskActivites([
                    ...taskActivities,
                    response.data.activity,
                ])
                setTasks(tasks.map(task => {
                    if(task.id === response.data.activity.task_id){
                        return {
                            ...task,
                            activities: [
                                ...task.activities,
                                response.data.activity,
                            ]
                        }
                    }else return task
                }))
            }

        })
        .catch(error => {
            console.log(error)
        })
    }

        
    async function setTableOptionsPref(){        
        let tableOptionsPref = await getPref('tableTasksOptions')
        if(tableOptionsPref !== null){
            setShowMyTasks(tableOptionsPref.showMyTasks)
            setShowFinishedTasks(tableOptionsPref.showFinishedTasks)
        }
    }

    function changeShowMyTasks(){
        let state = !showMyTasks
        setShowMyTasks(state)
        updatePref('tableTasksOptions', {
            showMyTasks: state,
            showFinishedTasks: showFinishedTasks,
        })
    }

    function changeShowFinishedTasks(){
        let state = !showFinishedTasks
        setShowFinishedTasks(state)
        updatePref('tableTasksOptions', {
            showMyTasks: showMyTasks,
            showFinishedTasks: state,
        })
    }

    return (
        <Scrollbars autoHide={true} autoHideDuration={250} autoHideTimeout={500} className="page">
            <div className="page-content">

                <Alert data={alertMessage} />

                <Window name="tasks" title={displayedTasks.length + ' Aufgaben'} actions={
                    <div style={{display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center'}}>
                        {myTasks.filter(t => t.status !== 1).length !== 0 && <span className="count">{myTasks.filter(t => t.status !== 1).length}</span>}
                        
                        <div style={{marginLeft:'auto'}}>
                            { checkScope('createGlobalTask') ? (
                                <button onClick={e => handleAddTask()}><FontAwesomeIcon icon={faPlus} /> Aufgabe erstellen</button>
                            ) : null }
                        </div>
                    </div>
                }>

                    <Table name="tableTasks" data={displayedTasks} perPage={25} headers={[
                        {
                            field: 'status',
                            label: 'Status',
                            type: 'taskstatus',
                            size: 's',
                        },
                        {
                            field: 'titel',
                            label: 'Titel',
                            type: 'text',
                            size: 'xl',
                        },
                        {
                            field: 'zieldatum',
                            label: 'Erledigt bis',
                            type: 'date',
                            size: 's',
                        },
                        {
                            field: 'user_id',
                            label: 'Betrifft',
                            type: 'user',
                            size: 'm',
                        },
                        {
                            field: 'bearbeiter',
                            label: 'Bearbeiter',
                            type: 'adminlist',
                            size: 'l',
                        },
                        {
                            field: 'von',
                            label: 'Ersteller',
                            type: 'admin',
                            size: 'm',
                        },
                    ]} onEdit={handleEditTask} footerContent={
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <label className="checkContainer">
                                <input type="checkbox" checked={showMyTasks} onChange={changeShowMyTasks} /> <span>Nur meine Aufgaben anzeigen</span>
                            </label>
                            <label className="checkContainer" style={{marginLeft: 20}}>
                                <input type="checkbox" checked={showFinishedTasks} onChange={changeShowFinishedTasks} /> <span>Erledigte Aufgaben anzeigen</span>
                            </label>
                        </div>
                    } />

                </Window>

                { addModal &&
                    <Modal title="Aufgabe erstellen" toggle={setAddModal} actions={
                        <div className="modal-actions">
                            <div style={{marginLeft:'auto'}}>
                                <button className="cancelButton" onClick={e => setAddModal(false)}>Abbrechen</button>
                                <button className="saveButton" onClick={e => saveAddTask()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                            </div>
                        </div>
                    }>
                        
                        <div className="row">
                            <div className="col">
                                <label>Status<br />
                                <Selectbox options={[
                                    { label: 'Offen', value: 0 },
                                    { label: 'In Bearbeitung', value: 2 },
                                    { label: 'Erledigt', value: 1 },
                                ]} name="status" state={addInputs} setState={setAddInputs} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Priorität<br />
                                <Selectbox options={[
                                    { label: 'Normal', value: 0 },
                                    { label: 'Hoch', value: 1 },
                                ]} name="priority" state={addInputs} setState={setAddInputs} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Zu erledigen bis<br />
                                <input type="date" value={addInputs.zieldatum} onChange={e => setAddInputs({...addInputs, zieldatum: e.target.value})} />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Titel<br />
                                <input type="text" value={addInputs.titel} onChange={e => setAddInputs({...addInputs, titel: e.target.value})} />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Beschreibung<br />
                                <textarea value={addInputs.beschreibung} onChange={e => setAddInputs({...addInputs, beschreibung: e.target.value})}></textarea>
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Betrifft<br />
                                <Selectbox options={users.map(user => {
                                    return { label: user.fullname + ' ' + (user.user_role === 1 ? '(BK)' : '(PB)'), value: user.id }
                                })} name="user_id" state={addInputs} setState={setAddInputs} placeholder="Intern" />
                                </label>
                            </div>
                            <div className="col">
                                <label>Bearbeiter<br />
                                <Selectbox options={admins.map(admin => {
                                    return { label: admin.vorname + ' ' + admin.nachname, value: admin.id }
                                })} name="bearbeiter" state={addInputs} setState={setAddInputs} multiple={true} />
                                </label>
                            </div>
                        </div>

                    </Modal>
                }

                { editModal &&
                    <Modal title="Aufgabe bearbeiten" toggle={setEditModal} actions={
                        <div className="modal-actions">
                            { checkScope('deleteTask', { 'created_by': editInputs.von }) ? <button className="deleteLink" onClick={e => handleDeleteTask()}>Aufgabe löschen</button> : null }
                            <div style={{marginLeft:'auto'}}>
                                <button className="cancelButton" onClick={e => setEditModal(false)}>Abbrechen</button>
                                <button className="saveButton" onClick={e => saveEditTask()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                            </div>
                        </div>
                    }>

                        <div className="row">
                            <div className="col">
                                <label>Status<br />
                                <Selectbox options={[
                                    { label: 'Offen', value: 0 },
                                    { label: 'In Bearbeitung', value: 2 },
                                    { label: 'Erledigt', value: 1 },
                                ]} name="status" state={editInputs} setState={setEditInputs} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Priorität<br />
                                <Selectbox options={[
                                    { label: 'Normal', value: 0 },
                                    { label: 'Hoch', value: 1 },
                                ]} name="priority" state={editInputs} setState={setEditInputs} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Zu erledigen bis<br />
                                <input type="date" value={editInputs.zieldatum} onChange={e => setEditInputs({...editInputs, zieldatum: e.target.value})} />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Titel<br />
                                <input type="text" value={editInputs.titel} onChange={e => setEditInputs({...editInputs, titel: e.target.value})} />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Beschreibung<br />
                                <textarea value={editInputs.beschreibung} onChange={e => setEditInputs({...editInputs, beschreibung: e.target.value})}></textarea>
                                </label>
                            </div>
                        </div>
                        <div>
                            
                            { taskActivities.map(activity => {
                                let admin = admins.find(a => a.id === activity.von)
                                let date = moment(activity.created_at).format('DD.MM.YYYY HH:mm')
                                let stateLabel = ''
                                if(activity.status === 0) stateLabel = 'Offen'
                                else if(activity.status === 1) stateLabel = 'Erledigt'
                                else if(activity.status === 2) stateLabel = 'In Bearbeitung'

                                return activity.type === 'text' ? (
                                    <div key={activity.id} className="task-activity">
                                        <div className="row task-activity-head">
                                            <div className="col">{admin.vorname + ' ' + admin.nachname}</div>
                                            <div className="col">{date}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col">{activity.beschreibung}</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div key={activity.id} className="task-activity">
                                        <div className="row task-activity-head">
                                            <div className="col" style={{flexGrow: 0, whiteSpace: 'nowrap'}}>{admin.vorname + ' ' + admin.nachname}</div>
                                            <div className="col" style={{fontWeight: 400}}>hat den Status auf <span className={'taskstate' + activity.status}>{stateLabel}</span> gestellt.</div>
                                            <div className="col">{date}</div>
                                        </div>
                                    </div>
                                )
                            })}

                            { !addTaskActivity ? (
                                <div className="table-actions">
                                    <button onClick={() => setAddTaskActivity(true)}><FontAwesomeIcon icon={faComment} /> Antworten</button>
                                </div>
                            ):(
                                <div style={{marginTop: 5}}>
                                    <label>Antwort<br />
                                        <textarea value={activityText} onChange={e => setActivityText(e.target.value)}></textarea>
                                    </label>
                                    <div className="table-actions">
                                        <button onClick={() => setAddTaskActivity(false)}>Abbrechen</button>
                                        <button onClick={saveTaskActivity} style={{marginLeft: 5}}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                                    </div>
                                </div>
                            )}
                            
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Betrifft<br />
                                <Selectbox options={users.map(user => {
                                    return { label: user.fullname + ' ' + (user.user_role === 1 ? '(BK)' : '(PB)'), value: user.id }
                                })} name="user_id" state={editInputs} setState={setEditInputs} placeholder="Intern" />
                                </label>
                            </div>
                            <div className="col">
                                <label>Bearbeiter<br />
                                <Selectbox options={admins.map(admin => {
                                    return { label: admin.vorname + ' ' + admin.nachname, value: admin.id }
                                })} name="bearbeiter" state={editInputs} setState={setEditInputs} multiple={true} />
                                </label>
                            </div>
                        </div>

                    </Modal>
                }

            </div>
        </Scrollbars>
    )

}

export default TasksScreen