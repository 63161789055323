import React, { useState, useEffect, useRef, useContext } from 'react'
import '../App.css'
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'
import { authAxios } from '../scripts/Axios'
import { useInterval } from '../scripts/Functions'
import { DataContext } from '../scripts/DataContext'
import { UserContext } from '../scripts/UserContext'
import { BadgeContext } from '../scripts/BadgeContext'
import Media from '../components/Media'
import Lightbox from '../components/Lightbox'

function Chat({user, updateUserBadges}) {

    const { admins } = useContext(DataContext)
    const { checkScope } = useContext(UserContext)
    const { updateBadges } = useContext(BadgeContext)

    const [showLightbox, setShowLightbox] = useState(false)
    const [lightboxIndex, setLightboxIndex] = useState(0)

    const chatScroll = useRef(null)
    const [chatLoading, setChatLoading] = useState(true)
    const [lastTime, setLastTime] = useState(moment())
    const [messages, setMessages] = useState([])
    const [groupedMessages, setGroupedMessages] = useState([])
    const [message, setMessage] = useState('')
    const [unreadCount, setUnreadCount] = useState(0)
    const [chatImages, setChatImages] = useState([])

    useInterval(() => updateChat(), 5 * 1000)

    useEffect(() => {

        authAxios.get('chat/' + user.id)
        .then(response => {

            setChatLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{

                let chats = response.data.chats
                if(chats.length){
                    setMessages(chats)
                    getChatUploads(chats)
                }

            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }, [])


    function getChatUploads(chats){
        let upload_ids = chats.filter(c => c.datei !== null).map(c => c.datei)
        if(!upload_ids.length) return

        authAxios.post('chat-uploads', {
            ids: upload_ids,
        })
        .then(response => {

            if(response.data.type !== 'error'){
                let uploads = response.data.uploads
                if(uploads.length) setChatImages(uploads)
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })
    }


    useEffect(() => {

        let chatGroups = messages.reduce((r, a) => {
            let date = moment(a.created_at).format('DD.MM.YYYY')
            r[date] = [...r[date] || [], a]
            return r
        }, {})

        let count = messages.filter(m => m.gelesen === 0 && m.von === 'user').length
        setUnreadCount(count)

        setGroupedMessages(chatGroups)

    }, [messages])



    useEffect(() => chatScroll.current.scrollToBottom(), [groupedMessages])



    function toggleLightbox(index){
        setLightboxIndex(index)
        setShowLightbox(true)
    }

    function updateChat(){

        authAxios.put('chat/' + user.id + '/updates', {
            lasttime: moment(lastTime).format('YYYY-MM-DD HH:mm:ss'),
        })
        .then(response => {
            
            if(response.data.type !== 'error'){

                let newMessages = response.data.chats                
                if(newMessages.length){
                    let newestMsg = newMessages[newMessages.length-1]
                    let newTime = moment(newestMsg.created_at).format('YYYY-MM-DD HH:mm:ss')
                    setLastTime(newTime)
                    setMessages([
                        ...messages,
                        ...newMessages,
                    ])
                }

            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }


    function sendMessage(){

        if(!message){ return }

        setChatLoading(true)

        authAxios.post('chat/' + user.id, {
            typ: 'text',
            inhalt: message.toString(),
        })
        .then(response => {

            setChatLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{

                updateChat()
                setMessage('')

            }

        })
        .catch(error => {
            console.log(error.response.data)
        })
    }


    function setChatRead(){
        
        authAxios.put('chat-read/' + user.id)
        .then(response => {
            
            if(response.data.type !== 'error'){
                setUnreadCount(0)
                updateBadges()
                updateUserBadges()
            }

        })
        .catch(error => {
            console.log(error)
        })

    }


    return (
        <div className="chat">

            { showLightbox &&
            <Lightbox files={chatImages} index={lightboxIndex} type="chat" onClose={() => setShowLightbox(false)} />
            }

            <Scrollbars ref={chatScroll} autoHide={true} autoHideDuration={250} autoHideTimeout={500} className="chatScroll">

                { messages.length <= 0 &&
                    <div className="nohits">Keine Chat-Nachrichten vorhanden.</div>
                }

                <div className="chatMessages">

                    { chatLoading &&                    
                        <svg className="spinner dark" viewBox="0 0 50 50">
                            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                        </svg>
                    }

                    { groupedMessages &&
                        Object.keys(groupedMessages).map(date => {
                            return (

                                <div key={date}>
                                    <div className="chatDate">{ date }</div>
                                    {
                                    groupedMessages[date].map((msg, index) => {
                                        let admin = admins.find(admin => admin.id == msg.von)
                                        return (

                                            <div key={msg.id} className={'chatMessage' + (msg.von === 'user' ? ' user' : '') + (msg.typ === 'datei' ? ' datei' : '')}>
                                                { admin && <div className="chatAuthor">{ admin.vorname + ' ' + admin.nachname }</div> }
                                                { msg.typ === 'text' ? (
                                                    <div className="chatContent">{ msg.inhalt }</div>
                                                ) : (
                                                    <Media key={msg.id} index={chatImages.findIndex(i => i.id === msg.datei)} file={msg.datei} type="chat" showLightbox={toggleLightbox} />
                                                ) }
                                                <div className="chatTime">{ moment(msg.created_at).format('HH:mm') }</div>
                                            </div>

                                        )
                                    })
                                    }
                                </div>

                            )
                        })
                    }

                </div>

            </Scrollbars>

            { checkScope('editUser', { 'created_by': user.created_by, 'assigned_to': user.assigned_to }) ? (
                <div className="chatControls">
                    <label className="checkContainer" style={{marginBottom:10}}>
                        <input type="checkbox" checked={unreadCount < 1} onChange={() => setChatRead()} /> <span>Alle Nachrichten gelesen</span>
                    </label>
                    <div style={{display:'flex', flexDirection:'row'}}>
                        <textarea type="text" placeholder="Nachricht ..." value={message} onChange={e => {
                            e.target.style.height = e.target.scrollHeight + 'px'
                            setMessage(e.target.value)
                        }}></textarea>
                        <button onClick={e => sendMessage()}>Senden</button>
                    </div>
                </div>
            ) : null }

        </div>
    )

}

export default Chat